import Cookies from 'js-cookie'
import { AUTH_COOKIE, BOARD_COOKIE, LANG_COOKIE } from '@/libs/constants'
import store from '../store'
import { isMobile, parseJwt } from '@/libs/utils'
import { getToken, isPlayGame } from '@/api/member'
import { subDays } from 'date-fns'

export function getAuthToken () {
  const tokenStr = getAuthCookie()
  if (tokenStr) {
    return tokenStr
  }
  return null
}

export function setPopupCookie (item) {
  const siteId = process.env.VUE_APP_SITE_ID || 'moka'
  const name = siteId + '_popup_' + item.bannerIdx
  const expires = subDays(new Date(), -1)

  Cookies.set(name, 'Y', {
    expires: expires,
    path: '/'
  })
}

export function getPopupCookie (name) {
  return Cookies.get(name, {
    path: '/'
  })
}

export function setBoardCookie (value = 1) {
  Cookies.set(BOARD_COOKIE.name, value, {
    path: LANG_COOKIE.path,
    domain: LANG_COOKIE.domain
  })
}

export function getBoardCookie () {
  return Cookies.get(BOARD_COOKIE.name, {
    path: LANG_COOKIE.path,
    domain: LANG_COOKIE.domain
  })
}

export function setLangCookie (value = 1) {
  document.body.classList = value
  Cookies.set(LANG_COOKIE.name, value, {
    path: LANG_COOKIE.path,
    domain: LANG_COOKIE.domain
  })
}

export function getLangCookie () {
  return Cookies.get(LANG_COOKIE.name, {
    path: LANG_COOKIE.path,
    domain: LANG_COOKIE.domain
  })
}

export function setAuthCookie (value = 1) {
  Cookies.set(AUTH_COOKIE.name, value, {
    expires: AUTH_COOKIE.expires,
    path: AUTH_COOKIE.path,
    domain: AUTH_COOKIE.domain
  })
}

function removeAuthCookie () {
  Cookies.remove(AUTH_COOKIE.name, {
    path: AUTH_COOKIE.path,
    domain: AUTH_COOKIE.domain
  })
}

export function getAuthCookie () {
  return Cookies.get(AUTH_COOKIE.name, {
    path: AUTH_COOKIE.path,
    domain: AUTH_COOKIE.domain
  })
}

export function setAuthData (data) {
  setAuthCookie(data.token)
}

export function removeAuthData () {
  removeAuthCookie()
  store.commit('setUserData', null)
  store.commit('setPopupLogin', [])
}

export function getStoreData () {
  return store.dispatch('storeUserData').then(userData => {
    store.dispatch('storeCommonCode').then(commonCode => {
      store.dispatch('storeCategoryCode').then(categoryCode => {
      })
    })
  }).then(() => {
    return true
  }).catch(err => {
    console.error(err)
    return false
  })
}

export function tokenCheck (force) {
  console.log('tokenCheck : ', force, window.self)
  const authToken = getAuthToken()
  if (authToken) {
    const parseToken = parseJwt(authToken)
    const expireTime = Number(parseToken.exp + '000')
    const currentTime = new Date().getTime()
    const diffMinute = (expireTime - currentTime) / 1000 / 60

    if (force || diffMinute <= 5) {
      return getToken().then(response => {
        const data = response.data
        if (data.resultCode === '0') {
          setAuthData(data.data)

          return getStoreData()
        } else {
          removeAuthData()
          if (isMobile()) {
            this.goPageByName('mobileMain')
          } else {
            location.href = '/'
          }
        }
      })
    } else {
      return true
    }
  } else {
    return false
  }
}

export async function isPlayGameStatus () {
  return isPlayGame().then(response => {
    const data = response.data
    if (data.resultCode === '0') {
      const result = data.data.isPlayGame
      console.log(result)
      if (result === 'Y') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  })
}
