export const striNew = {
  name: 'striNew',
  path: '/striNew',
  component: () => import('@/views/member/striNew'),
  meta: {
    layout: 'mobile_default',
    text: '총판페이지',
    gnb: false
  }
}
