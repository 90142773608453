<template>
    <div class="swiper-container live">
      <div class="swiper-wrapper">
        <template v-for="item in cashOutList" :key="item.memId">
           <div class="swiper-slide">
             <span>{{item.regDate}}</span>
             <span>{{item.memId}}</span>
             <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
           </div>
        </template>
        <template v-for="item in listMaxCount - cashOutList.length" :key="item">
        </template>
      </div>
    </div>
</template>

<script>
import { getMainCashStatus } from '@/api/main'
export default {
  name: 'UiSwiperb',
  title: 'live',
  data () {
    return {
      cashInList: [],
      cashOutList: [],
      listMaxCount: 3
    }
  },
  mounted () {
    this.loadSwiperb()
  },
  watch: {
  },
  computed: {
  },
  methods: {
    loadSwiperb () {
      // const list = this.banner
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.swiper-container.live', {
        slidesPerView: 1,
        direction: 'vertical',
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      })
    },
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    }
  },
  created () {
    this.loadCashStatus()
  }
}
</script>

<style src="@/styles/swiper.min.css"></style>

<style>

@media (max-width: 1000px) {
   .swiper-container.live {height: 33px;}
   .swiper-container.live .swiper-slide {font-size: 12px;color: #fff;display: flex;align-items: center;gap: 20px;}
}
</style>
