<template>
  <vue-final-modal v-slot="{ close }">
    <div class="loginmodal">
      <div class="art" :class="level"><!-- agreeart warningart -->
        <span class="img"></span>
        <p v-html="$t(code)"></p>
        <div class="btn">
          <template v-if="type == 'confirm'">
            <button class="yes" @click="$emit('confirm', 'yes', close)">{{$t('front.button.yes')}}</button>
            <button class="no" @click="$emit('confirm', 'no', close)">{{$t('front.button.no')}}</button>
          </template>

          <template v-if="type == 'check'">
            <button class="yes" @click="$emit('check', 'ok', close)">{{$t('front.button.confirm')}}</button>
          </template>
        </div>
        <button class="closeart" @click="onClose(close)"><img src="@/assets_mobile/img/icon_delete.svg" /></button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>

import Alert from '@/components/common/Alert'

export default {
  name: 'AlertMobile',
  mixins: [Alert]
}
</script>

<style scoped>
button {cursor: pointer}
.art {position: relative;width: 238px;margin: 0 auto;margin-top: 100px;padding: 30px;text-align: center;box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.4);border: 1px solid #dcdcdc;border-radius: 4px;background: #fff;}
.art h1 {font-size: 26px;font-weight: bold;}
.art p {margin-top: 15px;font-size: 14px;color: #000;line-height: 1.5em;font-weight: bold;}
.art .btn {display: flex;justify-content: space-between;gap: 10px;}
.art .yes, .art .no {width: 100%;height: 30px;font-size: 14px;border-radius: 2px;margin-top: 20px;}
.art .img {display: block;margin: 0 auto;margin-bottom: 25px;}
.agreeart .img {width: 60px;height: 28px;background: url('~@/assets/img/agreeart.svg')no-repeat;background-position: center;background-size: contain;}
.agreeart>h1 {color: #e5972d;}
.agreeart .yes {border: 1px solid #e4e4e4;color: #000;background: #e4e4e4;}
.agreeart .no {background: #999cff;color: #fff;border: 1px solid #999cff;}
.warningart .img {width: 39px;height: 36px;background: url('~@/assets/img/warningart.svg')no-repeat;background-position: center;background-size: contain;}
.warningart>h1 {color: #e1423c;}
.warningart .yes {border: 1px solid #e1423c;color: #fff;background-image: linear-gradient(to bottom, #e1423c, #a22924);}
.warningart .no {background: #555;color: #fff;border: 1px solid #555;}
.closeart {background: none;border: 0;position: absolute;right: 10px;top: 10px;}
.closeart img {height: 20px;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
